import { useEffect, useMemo, useState } from 'react';
import { groupBy, isEmpty, map, reduce, sortBy } from 'lodash';
import dayjs from 'dayjs';

import {
  useScheduleMeals,
  useListDrivers,
  useMealsProviderColor,
  useExportDriverTickets,
  useExportDeliveryPerDate,
} from '../../utils/api.hook';

import { ScheduleOverviewComponent } from './schedule-overview.component';
import schedules from '../schedules';

const latestAvailableDay = dayjs().day(1);
const PREVIEW_DAY = 5;

export function ScheduleOverviewContainer() {
  const [selectDate, setSelectDate] = useState(latestAvailableDay);
  const [listData, setListData] = useState<
    {
      name: string;
      leaveTimes: number[];
      data: { timestamp: number; list: schedules.CellData[] }[];
    }[]
  >([]);

  const timestampList = useMemo(() => {
    return map(Array(PREVIEW_DAY).fill(''), (_, index) =>
      selectDate.startOf('day').add(index, 'days')
    );
  }, [selectDate]);

  const { data: colorData = [], isLoading: isLoadingProviderColor } =
    useMealsProviderColor();
  const { data: { driversInfo } = {}, isLoading: isLoadingDrivers } =
    useListDrivers({
      startTime: timestampList[0].valueOf(),
      endTime: timestampList[PREVIEW_DAY - 1].valueOf(),
    });
  const {
    data: { deliveryMeals = [] } = {},
    isLoading: isLoadingScheduleMeals,
  } = useScheduleMeals({
    startTime: timestampList[0].valueOf(),
    endTime: timestampList[PREVIEW_DAY - 1].valueOf(),
  });
  const { mutateAsync: exportDriverTickets } = useExportDriverTickets();
  const { mutateAsync: exportDeliveryPerDate } = useExportDeliveryPerDate();

  const driverList = useMemo(() => {
    return sortBy(
      map(driversInfo, ({ user, ...driver }) => {
        const { profileId, id, ...userInfo } = user;

        return {
          ...driver,
          ...userInfo,
          label: `${driver.idNumber} - ${userInfo.name}`,
          value: driver.id,
        };
      }),
      'idNumber'
    );
  }, [driversInfo]);

  useEffect(() => {
    const initListData = async () => {
      let timestampAccumulate: {
        timestamp: number;
        list: schedules.CellData[];
      }[] = [];
      let timestampKey: number;

      for (let index = 0; index < timestampList.length; index++) {
        timestampKey = timestampList[index].valueOf();
        timestampAccumulate.push({
          timestamp: timestampKey,
          list: [],
        });
      }

      const mappingGroupByDriver = groupBy(deliveryMeals, 'driver.id');
      let mappingGroupByTimestamp: schedules.MappingGroupByTimestamp = {};

      const initData = reduce(
        driverList,
        (
          accumulate: {
            name: string;
            leaveTimes: number[];
            data: {
              timestamp: number;
              list: schedules.CellData[];
            }[];
          }[],
          item: schedules.Driver & { leaveTimes: number[] }
        ) => {
          let data: {
            timestamp: number;
            list: schedules.CellData[];
          }[] = [];
          if (!isEmpty(mappingGroupByDriver[item.id])) {
            mappingGroupByTimestamp = groupBy(
              mappingGroupByDriver[item.id],
              'time'
            );

            for (let { timestamp } of timestampAccumulate) {
              data.push({
                timestamp: Number(timestamp),
                list: !isEmpty(mappingGroupByTimestamp[timestamp])
                  ? map(mappingGroupByTimestamp[timestamp], (item) => ({
                      timestamp,
                      id: item.id,
                      provider: item.provider,
                      locationTag: item.locationTag,
                      driver: item.driver,
                      rank: item.rank,
                      status: item.status,
                      needToRecycleBags: item.needToRecycleBags,
                      time: item.time,
                    }))
                  : [],
              });
            }
          } else {
            data = timestampAccumulate;
          }

          accumulate.push({
            ...item,
            data,
          });

          return accumulate;
        },
        []
      );

      setListData(initData);
    };

    initListData();
  }, [deliveryMeals, timestampList, driverList]);

  return (
    <ScheduleOverviewComponent
      timestampList={timestampList}
      listData={listData}
      colorData={colorData}
      isLoading={
        isLoadingDrivers || isLoadingScheduleMeals || isLoadingProviderColor
      }
      setSelectDate={setSelectDate}
      exportDriverTickets={exportDriverTickets}
      exportDeliveryPerDate={exportDeliveryPerDate}
    />
  );
}
