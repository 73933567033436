import {
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Form,
  Space,
  Badge,
  Select,
  Button,
  Divider,
  Switch,
  Flex,
  Skeleton,
  Radio,
  Tooltip,
  List,
  Typography,
} from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import useModal from 'antd/es/modal/useModal';
import FormItem from 'antd/es/form/FormItem';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import {
  cloneDeep,
  filter,
  findIndex,
  isEmpty,
  map,
  reduce,
  uniqBy,
  values,
} from 'lodash';

import 'mapbox-gl/dist/mapbox-gl.css';

import {
  PROVIDER_STATUS_MAPPING,
  PROVIDER_STATUS_TYPE,
} from '../../constants/schedule-meals.types';
import schedules from '../schedules';

import { CalendarHeader, CalendarCell } from './meals-calendar.component';
import { MealsMapComponent } from './meals-map.component';

const todayTimestamp = dayjs().startOf('day');
const COLORS = [
  '#a8071a',
  '#ad4e00',
  '#ad8b00',
  '#5b8c00',
  '#52c41a',
  '#1677ff',
  '#722ed1',
  '#eb2f96',
  '#d48806',
  '#006d75',
];
const DEFAULT_COLOR = '#000000';

const PROVIDER_STATUS_OPTIONS = [
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.PENDING
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.PENDING,
  },
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.CONFIRMED
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.CONFIRMED,
  },
  {
    label: (
      <Badge
        status={
          PROVIDER_STATUS_MAPPING[
            PROVIDER_STATUS_TYPE.REJECTED
          ] as PresetStatusColorType
        }
      />
    ),
    value: PROVIDER_STATUS_TYPE.REJECTED,
  },
];

type DeliveryMeals = Array<{
  id: string;
  time: number;
  provider: schedules.Provider;
  locationTag: schedules.LocationTag;
  driver: schedules.Driver;
  rank: number;
  needToRecycleBags: boolean;
  status:
    | PROVIDER_STATUS_TYPE.PENDING
    | PROVIDER_STATUS_TYPE.CONFIRMED
    | PROVIDER_STATUS_TYPE.REJECTED;
}>;

type ScheduleMealsComponentProps = {
  locationTags: Array<schedules.LocationTag>;
  providerTagsInformation: {
    options: { label: string; options: { label: string; value: string }[] }[];
    mappingObject: {
      [key: string]: {
        name: string;
        type: string;
        address: string;
      };
    };
  };
  updateScheduleMeals: Function;
  deleteScheduleMeals: Function;
  updateScheduleMealStatus: Function;
  updateScheduleMealsRank: Function;
  upateScheduleMealsNote: Function;
  upateMealsProviderColor: Function;
  selectDate: Dayjs;
  setSelectDate: Function;
  listData: Record<
    string,
    {
      id: string;
      name: string;
      data: schedules.MappingGroupByTimestamp;
    }
  >;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  colorData: { providerId: string; color: string }[];
  todayDeliveryMeals: DeliveryMeals;
  deliveredMeals: any;
};

export function ScheduleMealsComponent(props: ScheduleMealsComponentProps) {
  const [modal, contextHolder] = useModal();
  const [form] = Form.useForm();
  const [noteForm] = Form.useForm();
  const providerIds = Form.useWatch('providerIds', form);

  const [isMapReady, setIsMapReady] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [providerColor, setProviderColor] = useState<{
    [providerId: string]: string;
  }>({});

  const initialValues = {
    providerIds: [],
  };

  const initialNotes = {
    notes: {},
  };

  useEffect(() => {
    noteForm.setFieldsValue({ notes: props.noteData });
  }, [props.noteData, noteForm]);

  useEffect(() => {
    const colorData = reduce(
      props.colorData,
      (
        accumulate: { [providerId: string]: string },
        item: {
          providerId: string;
          color: string;
        }
      ) => {
        accumulate[item.providerId] = item.color;

        return accumulate;
      },
      {}
    );

    setProviderColor(colorData);
  }, [props.colorData]);

  const isDisabledCalendarCell = useMemo(() => {
    return isEmpty(providerIds);
  }, [providerIds]);

  const moveItem = (item: {
    dragIndex: number;
    dropIndex: number;
    targetItem: any;
  }) => {
    if (item.dragIndex === null || item.dropIndex === null) {
      return;
    }

    const newListData = cloneDeep(
      props.listData[item.targetItem.locationTagId].data[
        item.targetItem.timestamp
      ]
    );
    const targetIndex = findIndex(
      newListData,
      (findItem) => findItem.rank === item.dragIndex
    );
    const [moveTarget] = newListData.splice(targetIndex, 1);
    newListData.splice(item.dropIndex, 0, moveTarget);
    const newDeliveryMealIds = map(newListData, 'id');

    props.updateScheduleMealsRank({
      locationTagId: item.targetItem.locationTagId,
      time: item.targetItem.timestamp,
      deliveryMealIds: newDeliveryMealIds,
    });
  };

  const renderListData = (
    cellData: Array<schedules.CellData>,
    timestamp: number
  ) => {
    return (
      <ul style={{ padding: 0 }}>
        {!isEmpty(cellData[timestamp]) &&
          map(cellData[timestamp], (item: schedules.CellData) => {
            const isEditable =
              isEditMode &&
              todayTimestamp.isBefore(dayjs(Number(timestamp)).add(1, 'day'));

            return (
              !isEmpty(item) && (
                <ListItemWrapper
                  key={`${item.locationTag.id}.${item.provider.id}.${timestamp}.${item.rank}`}
                  moveItem={moveItem}
                  locationTagId={item.locationTag.id}
                  timestamp={timestamp}
                  rank={item.rank}
                  disabledDrag={isEditable}
                >
                  <Flex component="li" gap="small" align="center">
                    {!isEditable ? (
                      <Badge
                        status={
                          item?.status
                            ? (PROVIDER_STATUS_MAPPING[
                                item.status
                              ] as PresetStatusColorType)
                            : 'default'
                        }
                        text={
                          <span
                            className="p-1"
                            style={{
                              color: providerColor[item.provider.id],
                              backgroundColor: item.needToRecycleBags
                                ? '#ffe58f'
                                : 'unset',
                            }}
                          >
                            {item.provider?.type}-{item.provider?.name}
                          </span>
                        }
                      />
                    ) : (
                      <>
                        <Flex gap="small" align="center">
                          <Select
                            size="small"
                            style={{ width: 43 }}
                            options={PROVIDER_STATUS_OPTIONS}
                            value={item.status}
                            onChange={(value) => {
                              props.updateScheduleMealStatus({
                                id: item.id,
                                status: value,
                              });
                            }}
                          />

                          <Tooltip
                            color="#fff"
                            title={
                              <Radio.Group
                                size="small"
                                value={
                                  providerColor[item.provider.id] ??
                                  DEFAULT_COLOR
                                }
                                onChange={(e) => {
                                  props.upateMealsProviderColor({
                                    providerId: item.provider.id,
                                    color: e.target.value,
                                  });
                                }}
                              >
                                {COLORS.map((item) => (
                                  <Radio.Button key={item} value={item}>
                                    <Badge color={item} size="default" />
                                  </Radio.Button>
                                ))}
                                <Radio.Button value={DEFAULT_COLOR}>
                                  預設
                                </Radio.Button>
                              </Radio.Group>
                            }
                            autoAdjustOverflow
                            destroyTooltipOnHide
                            trigger="click"
                            overlayStyle={{ maxWidth: 'none' }}
                          >
                            <span
                              className="p-1"
                              style={{
                                fontSize: 12,
                                textAlign: 'left',
                                color: providerColor[item.provider.id],
                                backgroundColor: item.needToRecycleBags
                                  ? '#ffe58f'
                                  : 'unset',
                              }}
                            >
                              {`${item.provider?.type}-${item.provider?.name}`}
                            </span>
                          </Tooltip>
                        </Flex>

                        <Button
                          icon={<DeleteOutlined />}
                          size="small"
                          onClick={async () => {
                            await props.deleteScheduleMeals([
                              {
                                time: timestamp,
                                locationTagId: item.locationTag.id,
                                providerId: item.provider.id,
                              },
                            ]);
                          }}
                        />
                      </>
                    )}
                  </Flex>
                </ListItemWrapper>
              )
            );
          })}
      </ul>
    );
  };

  const insertListData = async (locationTagId: string, timestamp: number) => {
    if (!isEmpty(providerIds)) {
      const params = map(providerIds, (providerId: string) => ({
        time: timestamp,
        locationTagId,
        providerId,
      }));

      await props.updateScheduleMeals(params);
    }
  };

  return (
    <Space direction="vertical" className="w-full page-schedule-meals">
      {contextHolder}

      <Form form={form} autoComplete="off" initialValues={initialValues}>
        <FormItem
          name="providerIds"
          label="供應商"
          rules={[{ required: true, message: '請選擇供應商' }]}
        >
          <Select
            allowClear
            disabled={!isMapReady}
            mode={'multiple'}
            optionFilterProp="label"
            options={props.providerTagsInformation.options}
            placeholder="請選擇供應商"
            showSearch
            labelRender={(item) =>
              `${props.providerTagsInformation.mappingObject[item.value].type} - ${item.label}`
            }
          />
        </FormItem>
      </Form>
      <MealsMapComponent
        providerIds={providerIds}
        setIsMapReady={setIsMapReady}
        providerTagsInformation={props.providerTagsInformation}
      />
      <Divider />

      <TodayRecycleBags todayDeliveryMeals={props.todayDeliveryMeals} />

      <Divider />
      <Switch
        checkedChildren="編輯模式"
        unCheckedChildren="閱讀模式"
        value={isEditMode}
        onChange={(isChecked) => setIsEditMode(isChecked)}
      />
      <Form form={noteForm} autoComplete="off" initialValues={initialNotes}>
        <CalendarHeader
          value={props.selectDate}
          onChange={(date: Dayjs) => {
            props.setSelectDate(date);
          }}
        />

        <List
          size="small"
          itemLayout="horizontal"
          split={false}
          dataSource={values(props.listData)}
          renderItem={(locationTagItem) => (
            <>
              <Divider />
              <CalendarCell
                key={locationTagItem.id}
                listData={locationTagItem.data}
                locationTagName={locationTagItem.name}
                locationTagId={locationTagItem.id}
                disabled={isDisabledCalendarCell}
                isEditMode={isEditMode}
                onClickAdd={(locationTagId: string, dateTimestamp: number) => {
                  insertListData(locationTagId, dateTimestamp);
                }}
                onCopyParams={(
                  locationTagId: string,
                  dateTimestamp: number
                ) => {
                  form.setFieldValue(
                    'providerIds',
                    map(
                      props.listData[locationTagId].data[dateTimestamp],
                      'provider.id'
                    )
                  );
                  form.validateFields(['providerIds'], { dirty: true });
                }}
                onSaveNote={async (
                  locationTagId: string,
                  dateTimestamp: number
                ) => {
                  const note = noteForm.getFieldValue([
                    'notes',
                    dateTimestamp,
                    locationTagId,
                  ]);
                  await props.upateScheduleMealsNote({
                    locationTagId,
                    time: Number(dateTimestamp),
                    note,
                  });
                }}
                noteData={props.noteData}
                renderListData={renderListData}
                deliveredMeals={props.deliveredMeals}
                modal={modal}
              />
            </>
          )}
        />
      </Form>
    </Space>
  );
}

const ListItemWrapper = (props: {
  moveItem: Function;
  locationTagId: string;
  timestamp: number;
  rank: number;
  disabledDrag: Boolean;
  children: ReactElement<any, string>;
}) => {
  const ref = useRef(null);

  const [, drag] = useDrag({
    type: 'LIST_ITEM',
    item: {
      locationTagId: props.locationTagId,
      timestamp: props.timestamp,
      rank: props.rank,
    },
    canDrag: () => !!props.disabledDrag,
    end: (dropResult, monitor) => {
      const dragItem = monitor.getItem();
      const didDrop = monitor.didDrop();

      if (didDrop) {
        const dropTarget: {
          locationTagId: string;
          timestamp: number;
          rank: number;
        } | null = monitor.getDropResult();

        props.moveItem({
          dragIndex: dragItem.rank,
          dropIndex: dropTarget?.rank,
          targetItem: dragItem,
        });
      }
    },
  });

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'LIST_ITEM',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (dragItem: {
      locationTagId: string;
      timestamp: number;
      rank: number;
    }) => {
      return (
        dragItem.timestamp === props.timestamp && dragItem.rank !== props.rank
      );
    },
    hover: (dragItem: any, monitor) => {
      const dragIndex = dragItem.rank;
      const dropIndex = props.rank;

      if (dragIndex === dropIndex || props.timestamp !== dragItem.timestamp) {
        return;
      }
    },
    drop: () => ({
      locationTagId: props.locationTagId,
      timestamp: props.timestamp,
      rank: props.rank,
    }),
  }));

  drag(drop(ref));

  return (
    <Flex ref={ref} vertical>
      {props.children}
      {isOver && canDrop && <Skeleton.Input block />}
    </Flex>
  );
};

const TodayRecycleBags = memo(
  (props: { todayDeliveryMeals: DeliveryMeals }) => {
    const recycleBags = useMemo(
      () =>
        uniqBy(
          filter(props.todayDeliveryMeals, (item) => item.needToRecycleBags) ||
            [],
          'provider.id'
        ),
      [props.todayDeliveryMeals]
    );

    return (
      <Typography.Text>
        <>
          今日回收店家：
          {recycleBags.length
            ? map(recycleBags, (item) => item.provider.name).join('、')
            : '無'}
        </>
      </Typography.Text>
    );
  }
);
